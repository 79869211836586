import React from "react"
import { Global, css } from "@emotion/react"

export const breakpoints =  {
  mobile: '@media (max-width: 768px)',
  tablet: '@media (max-width: 1024px)',
  desktop: '@media (max-width: 1230px)',
  desktopUp: '@media (min-width: 1025px)'
}
const { mobile } = breakpoints

const GlobalStyles = () => (
  <>
  <Global styles={reset} />
  <Global styles={styles} />
  </>
)

const styles = css`

  @keyframes floating {
    0%   {
      transform: translateY(1rem);
    }
    50% {
      transform: translateY(-1rem);
    }
    100% {
      transform: translateY(1rem);
    }
  }

  @keyframes shadow-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }

  html{
    background-color: var(--beige);
    font-size: 10px;
    ${mobile}{
      font-size: 7px;
    }
  }

  body {
    font-family: 'Agrandir', sans-serif;
    letter-spacing: 0.03em;
  }

  :root{
    --white: #ffffff;
    --black: #000000;
    --beige: #FBEFE1;
    --gold: #9D8160;
    --pink: #FD6E5F;
    --lightPink: #FFB4A9;
    --green: #204723;
    --lightGreen: #D3E1A2;
    --purple: #B9B3FC;
    --orange: #FFB65B;
    --lightGrey: #F1F1F1;
    --standard: 10px;
  }

  h1, .h1{
    font-size: 60px;
    line-height: 1.3;
    ${mobile}{
      font-size: 36px;
    }
    &.serif {
      font-family: 'Romie', sans-serif;
    }
  }

  h2, .h2{
    font-size: 27px;
    line-height: 1.3;
    ${mobile}{
      font-size: 19px;
    }
  }

  h3, .h3{
    font-size: 20px;
    line-height: 1.3;
    ${mobile}{
      font-size: 16px;
    }
  }

  h4, .h4{
    font-size: 14px;
    line-height: 1.3;
    text-transform: uppercase;
    ${mobile}{
      font-size: 12px;
    }
  }

  body, .p{
    font-size: 20px;
    line-height: 1.3;
    ${mobile}{
      font-size: 16px;
    }
  }

	body, .p{
    font-size: 15px;
    line-height: 1.3;
    ${mobile}{
      font-size: 12px;
    }
  }

  p{
    margin-bottom: 0.5em;
    &:last-child{
      margin-bottom: 0;
    }
  }
`

export const Section = ({ children, className }) => (
  <section className={className}>
    <div css={css`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 30px;
      padding: 0 60px;
      ${mobile}{
        grid-column-gap: 20px;
        padding: 0 20px;
      }
      `
    }>
    {children}
    </div>
  </section>
)

const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 500px white inset !important;
  }

	/* a, button, select {
    color: inherit;
  } */

	/* Prevent safari blue links */
	button, select, option, {
    color: inherit;
  }
`

export default GlobalStyles
