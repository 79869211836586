/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"

import { SiteStore } from "~context/siteContext"

export const wrapRootElement = ({ element }) => (
  <SiteStore>{element}</SiteStore>
)

const transitionDelay = 700
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if(location.state?.disableScrollUpdate === true){
    const { disableScrollUpdate } = location.state
    return !disableScrollUpdate
  }
  window.history.scrollRestoration = "manual"
  const currentPosition = getSavedScrollPosition(location)
  window.setTimeout(() => {
    window.scrollTo(...currentPosition)
  }, transitionDelay )
  return false
}