import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'

const Incrementer = ({ onChange, initialValue, className, maxValue }) => {
    
    const { mobile } = breakpoints

    const [value, setValue] = useState(0)

    useEffect(() => {
        setValue(initialValue ?? 1)
    }, [initialValue])
    
    const buttonCss = `
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    `

    const updateValue = newValue => {
        if(newValue > 0 && newValue <= maxValue){
          setValue(newValue)
          if(onChange){
            onChange(newValue)
          }
        }
        return
    }

    return (
        <div 
            className={className}
            css={css`
                position: relative;
                width: 88px;
                height: 40px;
                ${mobile} {
                    height: 29px;
                }
        `}>
            <button 
                onClick={() => updateValue(value - 1)}
                css={css`
                    ${buttonCss}
                    left: 12px;
            `}>-</button>
            <input 
                type="text" 
                readOnly={true} 
                value={value}
                css={css`
                    -moz-appearnace: none;
                    -webkit-appearance: none;
                    border: none;
                    height: 100%;
                    text-align: center;
                    display: block;
                    width: 100%;
                    box-sizing: border-box;
                    border: 1px solid var(--black);
                    border-radius: 100px;
                    font-family: inherit;
            `}/>
            <button 
                onClick={() => updateValue(value + 1)}
                css={css`
                    ${buttonCss}
                    top: calc(50% + 2px);
                    right: 12px;
            `}>+</button>
        </div>
    )
}

Incrementer.propTypes = {
    onChange: PropTypes.func,
    initialValue: PropTypes.number,
}

Incrementer.defaultProps = {
    initialValue: 2,
}

export default Incrementer
