const resolveLink = (type, slug, variantId) => {
  if(!type) return null

  if(variantId && type === 'variant'){
    return `/products/${slug}?v=${variantId}`
  }
  
  switch (type) {
    case 'product':
      return `/products/${slug}`
    case 'shopPage':
      return `/shop`
    case 'homePage':
      return `/`
    case 'maker':
      return `/makers/${slug}`
    case 'featuredProduct': 
      return `/products/${slug}`
    default:
      return `/${slug}`
  }

}

export default resolveLink
