import React, { useState, useContext, useEffect } from 'react'
import ShopifyClient from 'shopify-buy'
import Cookies from 'js-cookie'

const client = ShopifyClient.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN,
  domain: process.env.GATSBY_SHOPIFY_STORE
})

const initialSiteState = {
  pageTitle: null,
  cart: [],
  cartOpen: false,
  menuOpen: false,
	announcementBar: true
}

export const SiteContext = React.createContext({
  siteState: initialSiteState,
  setSiteState: undefined,
})

export const SiteStore = ({ children }) => {
  const [siteState, setSiteState] = useState(initialSiteState)
  const [init, setInit] = useState(false)

  useEffect(() => {
    if(init){
      localStorage.setItem('TPTB_CART', JSON.stringify(siteState.cart), { expires: 2 })
    }
  }, [siteState])

  const loadCartFromCookie = () => {
    const cartCookie = localStorage.getItem('TPTB_CART')
    if(cartCookie){
      setSiteState({
        ...siteState,
        cart: JSON.parse(cartCookie)
      })
    }
    setInit(true)
  }

  const destroyCart = () => {
    Cookies.remove('TPTB_CHECKOUT_ID')
    localStorage.removeItem('TPTB_CART')
    setInit(true)
  }

  useEffect(() => {
    const id = Cookies.get('TPTB_CHECKOUT_ID')
    if(id){
      client.checkout.fetch(id).then((checkout) => {
        if(checkout.completedAt){
          destroyCart()
        } else {
          loadCartFromCookie()
        }
      })
    } else {
      loadCartFromCookie()
    }
  }, [])

  return(
    <SiteContext.Provider value={{
      siteState: siteState,
      setSiteState: setSiteState
    }}>
      {children}
    </SiteContext.Provider>
  )
}

// hook to access siteState globally
export const useSiteState = () => {
  const { siteState, setSiteState } = useContext(SiteContext)
  return [siteState, setSiteState]
}

// Cart functions

export const useCart = () => {
  const {siteState, setSiteState} = useContext(SiteContext)

  const addItem = item => {
    let itemIndex = siteState.cart.findIndex(c => c.variantId === item.variantId)
    if(itemIndex === -1){
      setSiteState(prevState => ({
        ...prevState,
        cartOpen: true,
        cart:[
          ...siteState.cart,
          item
        ]
      }))
    }
    else if(item.qty <= siteState.cart[itemIndex].maxQty){
      let newCart = siteState.cart
      newCart[itemIndex].qty += item.qty
      setSiteState({
        ...siteState,
        cartOpen: true,
        cart: newCart
      })
    }
  }

  const updateQty = (variantId, qty) => {
    let itemIndex = siteState.cart.findIndex(c => c.variantId === variantId)
    if(itemIndex === -1){
      return null
    }
    if(qty > siteState.cart[itemIndex].maxQty){
      return null
    }
    let newCart = siteState.cart
    newCart[itemIndex].qty = qty
    setSiteState({
      ...siteState,
      cart: newCart
    })
  }

  const removeItem = variantId => {
    let itemIndex = siteState.cart.findIndex(c => c.variantId === variantId)
    if(itemIndex === -1){
      return null
    }
    let newCart = siteState.cart
    newCart.splice(itemIndex, 1)
    setSiteState({
      ...siteState,
      cart: newCart
    })
  }

  const cartCount = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue.qty
    return [...siteState.cart].reduce(reducer, 0)
  }

  const cartTotal = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue.price * currentValue.qty
    return [...siteState.cart].reduce(reducer, 0)
  }

  return {
    cart: siteState.cart,
    cartCount: cartCount,
    cartTotal: cartTotal,
    addItem: addItem,
    updateQty: updateQty,
    removeItem: removeItem
  }

}
