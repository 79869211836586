import { graphql, useStaticQuery } from "gatsby"

const useSiteSettings = () => {

  const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings(_id: {regex: "/(drafts\\\\.)?siteSettings/"}) {
        _rawSocialImage(resolveReferences: {maxDepth: 1})
        metaDescription
        siteTitle
				announcementMessage: _rawAnnouncementMessage(resolveReferences: {maxDepth: 5})
				announcementBarColour
      }
    }
    `)

  const settings = siteSettings.sanitySiteSettings

  return {
    siteTitle: settings.siteTitle,
    siteMetaDescription: settings.metaDescription,
    siteSocialImage: settings._rawSocialImage,
		announcementMessage: settings.announcementMessage,
		announcementBarColour: settings.announcementBarColour
  }
}

export default useSiteSettings
