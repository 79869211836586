import React, { useEffect, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { css, Global } from '@emotion/react';
import { Link } from 'gatsby';
import { Logo, ShoppingCart } from '../Svg';
import Hamburger from '../Hamburger';
import MobileMenu from '../MobileMenu';
import { useSiteState, useCart } from "~context/siteContext"

const MobileHeader = ({ menuOpen, className, isHome }) => {

  const [siteState, setSiteState] = useSiteState()
  
  const { cartCount } = useCart() 

  const openCart = ()=> {
    if(siteState.menuOpen) {
      setSiteState({...siteState, menuOpen: false, cartOpen: true})
    } else {
      setSiteState({...siteState, cartOpen: true})
    }
  }

  return (
    <>
      <Global styles={css`
        html{
          overflow-y: ${siteState.menuOpen ? 'hidden' : 'scroll'};
        }
      `}/>
      <header 
        className={className}
        css={css`
          position: inherit;
          width:100%;
          box-sizing:border-box;
          top: 0px;
          height: 70px;
          z-index: 2;
          height: 100%;
          align-items: start;
          pointer-events: ${ siteState.menuOpen ? `all` : `none` }; 
      `}>
        <div css={css`
          display: grid;
          grid-template-columns: 1fr 130px 1fr;
          padding: 3.5rem 3rem;
          position: relative;
          z-index: 2;
        `}>
            <div css={css`
              display: grid;
              justify-content: start;
            `}>
              <button 
                onClick={() => setSiteState({...siteState, menuOpen: !siteState.menuOpen})}
                onKeyUp={() => setSiteState({...siteState, menuOpen: !siteState.menuOpen})}
                css={css`
                  display: grid;
                  height: 25px;
                  width: 25px;
                  align-items: center;
                  pointer-events: all;
              `}>
                <Hamburger menuOpen={siteState.menuOpen} mode={isHome ? 'light' : ''}/>
              </button>
            </div>
            <Link to="/" css={css`
              opacity: ${ siteState.menuOpen ? `0` : `1` }; 
              pointer-events: ${ siteState.menuOpen ? `none` : `all` }; 
              transition: opacity 0.3s;
            `}>
              <Logo css={css`
                color: ${ isHome ? 'var(--white)' : 'var(--black)' };
                transition: color 0.3s;
              `}/>
            </Link>
            <div css={css`
              display: grid;
              justify-content: end;
            `}>
              <button 
                onClick={() => openCart()}
                css={css`
                  color: inherit;
                  width: 25px;
                  height: 25px;
                  display: grid;
                  position: relative;
                  pointer-events: all;
              `}
              >
                <ShoppingCart css={css`
                  color: ${ (siteState.menuOpen || isHome) ? 'var(--white)' : 'var(--black)' };
                  width: 19px;
                  transition: color 0.3s;
                `}/>
                <div css={css`
                  display: ${ cartCount() > 0 ? 'grid' : 'none' };
                  align-items: center;
                  border-radius: 100px;
                  background: ${ (siteState.menuOpen || isHome) ? 'var(--white)' : 'var(--black)' };
                  color: ${ (siteState.menuOpen || isHome) ? 'var(--black)' : 'var(--white)' };
                  transition: 0.3s;
                  font-size: 9px;
                  min-width: 12px;
                  height: 12px;
                  position: absolute;
                  right: 0px;
                  bottom: 0px;
                  padding: 0 3px;
                  box-sizing: border-box;
                `}>
                  <span css={css`
                    margin-top: 1px;
                  `}>{cartCount() > 0 ? `${cartCount()}`: null}</span>
                </div>
              </button>
            </div>
        </div>
        <MobileMenu visible={siteState.menuOpen} />
      </header>
    </>
  )
}

MobileHeader.propTypes = {
  mode: PropTypes.oneOf(['light', 'dark']),
  menuOpen: PropTypes.bool,
  cartItemsNumber: PropTypes.number,
}

MobileHeader.defaultProps = {
  // mode: 'dark',
  // cartItemsNumber: 1,
  menuOpen: false,
}

export default MobileHeader
