import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const Hamburger = ({ menuOpen, mode }) => {

  const hamburgerCss = css`
    width:20px;
    height:14px;
    transition: opacity 0.5s;
    position: relative;
    margin-right: auto;
    pointer-events: all;
    cursor:pointer;
    &:focus{
        outline: none;
    }
    &:after{
        content: "";
        display: block;
        position: absolute;
        top: -20px;
        right: 0;
        left: 0;
        bottom: -20px;
    }
    div{
        height:1.1px;
        /* fix android menu line thicknesses */
        width:100%;
        background-color: ${ (menuOpen || mode === 'light') ? 'var(--white)' : 'var(--black)' };
        position: absolute;
        transition: width 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s;
        right:0px;
        border-radius: 10px;
        &:nth-of-type(1){
            top:0px;
            transition-delay: ${ menuOpen ? '0s' : '0.3s' }, ${ menuOpen ? '0s' : '0.3s' }, 0s;
            width: ${ menuOpen ? '0px' : '100%' };
            background-color: ${ (menuOpen || mode === 'light') ? 'var(--white)' : 'var(--black)' };
        }
        &:nth-of-type(2){
            top:7px;
            transition-delay: ${ menuOpen ? '0.3s' : '0s' }, ${ menuOpen ? '0.3s' : '0s' }, 0s;
            transform: ${ menuOpen ? 'rotate(45deg)' : 'rotate(0deg)' };
            background-color: ${ (menuOpen || mode === 'light') ? 'var(--white)' : 'var(--black)' };
        }
        &:nth-of-type(3){
            top:7px;
            transition-delay: ${ menuOpen ? '0.3s' : '0s' }, ${ menuOpen ? '0.3s' : '0s' }, 0s;
            transform: ${ menuOpen ? 'rotate(-45deg)' : 'rotate(0deg)' };
            background-color: ${ (menuOpen || mode === 'light') ? 'var(--white)' : 'var(--black)' };
        }
        &:nth-of-type(4){
            top:14px;
            transition-delay: ${ menuOpen ? '0s' : '0.3s' }, ${ menuOpen ? '0s' : '0.3s' }, 0s;
            width: ${ menuOpen ? '0px' : '100%' };
            background-color: ${ (menuOpen || mode === 'light') ? 'var(--white)' : 'var(--black)' };
        }
    }
  `

  return (
        <div css={hamburgerCss} role="button" tabIndex={0}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
  )
}

Hamburger.propTypes = {
    mode: PropTypes.oneOf(['light', 'dark']),
    menuOpen: PropTypes.bool,
}

Hamburger.defaultProps = {
    mode: 'dark',
}

export default Hamburger
