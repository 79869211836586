import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react';
import { Link, navigate } from 'gatsby';
import { useSiteState } from "~context/siteContext"

const MobileMenu = ({ className, visible }) => {

    const [siteState, setSiteState] = useSiteState()

    const navLinks = [
      {
        link: `/shop`,
        name: `Shop`,
      },
      {
        link: `/makers`,
        name: `Makers`,
      },
      {
        link: `/about`,
        name: `About`,
      },
      {
        link: `/delivery`,
        name: `Delivery`,
      },
      {
        link: `/faq`,
        name: `FAQ`,
      }
    ]

    const navigateToLink= link => {
      setSiteState({...siteState, menuOpen: !siteState.menuOpen})
      navigate(link)
    }

    return (
        <nav
            className={className}
            css={css`
            width: 100%;
            background-color: var(--green);
            padding: 10rem 3rem 2rem;
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            box-sizing: border-box;
            opacity: ${visible ? '1' : '0'};
            pointer-events: ${visible ? 'all' : 'none'};
            transition: opacity 0.5s;
        `}>
            <ul css={css`
                list-style-type: none;
                color: var(--white);
                transition: transform 0.5s;
                transform: ${visible ? 'translateX(0)' : 'translateX(-50px)'};
            `}>
                {navLinks.map(navLink => (
                    <li key={navLink.key}
                        css={css`
                            margin: 0px 0px 5px;
                        `}
                        className="h1"
                    >
                        <button css={css`color: var(--white);`}onClick={()=> navigateToLink(navLink.link)}>
                            {navLink.name}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

MobileMenu.propTypes={
    navLinks: PropTypes.arrayOf(PropTypes.shape({
        name:PropTypes.string,
        link: PropTypes.string,
        key: PropTypes.string,
      }).isRequired)
}

MobileMenu.defaultProps = {
    navLinks: [
        // {name:'Shop',link:"/shop", key:"shop"},
        // {name:'Makers',link:"/makers", key:"makers"},
        // {name:'About',link:"/about", key:"about"}
    ],
    visible: true
}


export default MobileMenu;
