import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from "gatsby"
import { breakpoints } from "~styles/global"
const { mobile } = breakpoints

const NavLink = ({ children, to, onClick, activeColor, className, previewActive }) => {
  if(to){
    return (
      <Link
        to={to}
        css={css`
        display: grid;
        grid-template-columns: min-content min-content;
        align-items: center;
          &:before{
              content: '';
              display: none;
              background-color: ${activeColor};
              border-radius: 100%;
              width: 0.7em;
              height: 0.7em;
              margin-right: 0.4em;
              margin-bottom: 0.2em;
              ${mobile} {
                margin-bottom: 0.3em;
              }
          }
          &.active:before{
              display: inline-block;
          }
        `}
        className={`h2 ${className} ${previewActive && 'active'}`}
        activeClassName="active"
      >
        {children}
      </Link>
    )
  }
  else if(onClick) {
    return (
      <button
        onClick={e => onClick(e)}
        className={`h2 ${className}`}
      >
        {children}
      </button>
    )
  }
  else {
    return null
  }
}

NavLink.propTypes = {
    children: PropTypes.string.isRequired,
    to: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    activeColor: PropTypes.string,
    previewActive: PropTypes.bool,
}

NavLink.defaultProps = {
    children: 'Link',
    to: '/test',
    activeColor: '#B9B3FC',
    previewActive: false,
}


export default NavLink
