import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css, Global } from '@emotion/react';
import { Cross } from '../Svg';
import ProductLineItem from '../ProductLineItem';
import Button from '../Button';
import { breakpoints } from '~styles/global'
import { useCart, useSiteState } from "~context/siteContext"
import resolveLink from "~utils/resolveLink"
import prepareCheckout from '~utils/prepareCheckout'

const Cart = ({ className, open }) => {

  const { mobile } = breakpoints
  const { cartTotal, cart } = useCart()
  const [siteState, setSiteState] = useSiteState()
  const [loading, setLoading] = useState(false)

  const products = siteState.cart

  const loadCheckout = () => {
    setLoading(true)
    prepareCheckout(cart)
  }

  return (
    <>
      <Global styles={css`
        html{
          overflow-y: ${open ? 'hidden' : 'scroll'};
        }
      `}/>
      <div className={className} css={css`
        background-color: var(--beige);
        padding: 4rem 2rem;
        display: grid;
        grid-template-rows: min-content 1fr min-content;
        position: fixed;
        z-index: 6;
        width: 600px;
        top: 0;
        right: 0;
        bottom: 0;
        transform: ${open ? 'translateX(0)' : 'translateX(100%)'};
        pointer-events: ${open ? 'all' : 'none'};
        transition: transform 0.5s;
        box-sizing: border-box;
				overflow-y: scroll;
        ${mobile}{
          width: 100%;
        }
      `}>
          <div css={css`
            display: grid;
            grid-template-columns: 1fr min-content;
            margin-bottom: 5rem;
          `}>
            <h2>Cart</h2>
            <button 
              onClick={() => setSiteState(prevState => ({
                ...prevState,
                cartOpen: false
              }))}
              css={css`
                align-self: end;
            `}>
              <Cross css={css`
                width: 21px;
              `}/>
            </button>
          </div>
          <div>
            {products.length ? 
              products?.map(product => (
                <ProductLineItem
                  name={product.title}
                  id={product.variantId}
                  link={resolveLink(`variant`, product.slug, product.variantId)}
                  price={product.price}
                  qty={product.qty}
                  maxQty={product.maxQty}
                  color={product.variant}
                  key={product.variantId}
                  image={product.image}
                />
              ))
            :
            <p>
              Your Cart is Empty
            </p> 
            }
            
          </div>
          {products.length ?
          <div css={css`
            display: grid;
            grid-template-columns: auto;
            justify-content: center;
            align-self: end;
          `}>
            <div css={css`
              display: grid;
              grid-template-columns: min-content min-content;
              justify-content: center;
              align-items: center;
              grid-column-gap: 2rem;
              margin-bottom: 2rem;
            `}>
              <h4>total</h4>
              <h3>${(Math.round(cartTotal() * 100) / 100).toFixed(2)}</h3>
            </div>
            <Button onClick={() => loadCheckout(cart)}  css={css`
              margin-bottom: 1rem;
            `}>{loading ? 'Loading...' : 'Check out'}</Button>
            <Button 
              onClick={() => setSiteState(prevState => ({
                ...prevState,
                cartOpen: false
              }))}>
                Keep Shopping
            </Button>
          </div>
          : 
          null
          }
      </div>
    </>
  )
}

Cart.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
}

Cart.defaultProps = {
    products: [
        {name:'Product',link:"/", price: 40.00, quanity: 1, color: 'color1', key:"product" },
        {name:'Product 2',link:"/", price: 20.00, quanity: 1, color: 'color2', key:"product2" }
    ],
    open: true,
}

export default Cart
