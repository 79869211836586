import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Image from '~components/Image'
import Incrementer from '~components/Incrementer';
import { CrossSmall } from '../Svg';
import { breakpoints } from '~styles/global'
import { useCart } from '~context/siteContext'

const ProductLineItem = ({ image, name, price, color, qty, maxQty, className, id, link }) => {

  const { mobile } = breakpoints
  const { updateQty, removeItem } = useCart()

  return (
    <div css={css`
        border-radius: var(--standard);
        overflow: hidden;
        display: grid;
        grid-template-columns: 26% 1fr;
        background-color: var(--white);
        margin-bottom: 2rem;
        ${mobile} {
          grid-template-columns: 30% 1fr;
        }
    `}>
      <Image  asset={image} aspectRatio={1} css={css`.gatsby-image-wrapper{height: 100%; object-fit: cover;}`}/>
      <div css={css`
        padding: 1.5rem 1.5rem 1.5rem 2.5rem;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-template-rows: min-content min-content min-content;
        ${mobile} {
          padding: 1.5rem;
          grid-template-columns: min-content 1fr 1fr;
        }
      `}>
        <h2 css={css`
            ${mobile}{
              grid-column: 1 / span 2;
            }
        `}>
          {name}
        </h2> 
        <h3 css={css`
            grid-column: 2;
            grid-row: 1 / span 1;
            justify-self: end;
            ${mobile}{
              grid-column: 2;
              grid-row: 2 / span 1;
            }
        `}>
        ${price}
        </h3>
				<h3 css={css`
							grid-column: 1;
							grid-row: 2 / span 1;
							margin-bottom: 1.5rem;
							display: ${color === 'Default Title' ? 'none' : 'block'};
					`}>
					{color}
				</h3>
        <Incrementer 
          onChange={value => updateQty(id, value)}
          initialValue={qty}
          maxValue={maxQty}
          css={css`
            grid-column: 1;
            grid-row: 3 / span 1;
						margin-top: 8px;
        `}/>
        <button 
          onClick={() => removeItem(id)}
          css={css`
            grid-column: 3;
            grid-row: 1 / span 3;
            justify-self: end;
            align-self: start;
        `}>
          <CrossSmall css={css`
              width: 14px;
          `}/>
        </button>
      </div>
    </div>
  )
}

ProductLineItem.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string, 
  color: PropTypes.string, 
  qty: PropTypes.number, 
  id: PropTypes.number, 
}

ProductLineItem.defaultProps = {
  name: 'Product',
  price: 59.00,
  color: 'green'
}

export default ProductLineItem
