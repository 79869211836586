import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled'
import { Link, useStaticQuery,  graphql} from "gatsby"
import { Section, breakpoints } from '~styles/global'
import { TPTBDefault, TPTBColor } from '../Svg';
import resolveLink from '~utils/resolveLink'

const { mobile, tablet } = breakpoints  

const Footer = () => {

  const [hovering, setHovering] = useState(false)

  const listColumsCss = css`
    grid-column: span 1;
    ${mobile}{
        grid-column: span 3;
    }
  `
  const listHeadingCss = css`
    margin-bottom: 2.5rem;
  `

  const data = useStaticQuery(graphql`
    {
        allSanityPage {
            nodes {
              title
              _type
              slug {
                current
              }
            }
          }
        allSanityMaker(sort: {fields: title}) {
            nodes {
                title
                _type
                slug {
                current
                }
            }
        }
    }
  `)

  const infoLinks = data.allSanityPage.nodes
  const makerLinks = data.allSanityMaker.nodes
  
  return (
    <footer>
        <Section css={css`
            background-color: var(--white);
            padding-top: 7rem;
            padding-bottom: 7rem;
            ${mobile}{
                padding-top: 6rem;
                padding-bottom: 6rem;
            }
        `}>
            <div
                
                css={css`
                    grid-column: span 6;
                    ${mobile}{
                        align-self: end;
                    } 
                `}
            >
                <button css={css`
                    position: relative;
                    width: 60px;
                    ${mobile}{
                        width: 38px;
                    }`}
                    onMouseEnter={() => window.innerWidth > 1024 ? setHovering(true) : null}
                    onMouseLeave={() => setHovering(false)}
                    onClick={() => window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      })}
                >
                    <TPTBDefault css={css`
                        opacity: ${hovering ? '0' : '1'};
                        transition: opacity 0.3s;
                    `}/>
                    <TPTBColor css={css`
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        transition: opacity 0.3s;
                        opacity: ${hovering ? '1' : '0'};
                    `}/>
                </button>
            </div>
            <div css={css`
                grid-column: span 6; 
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 30px;
                ${mobile}{
                    grid-column-gap: 20px;
                }
            `}>
                <div css={listColumsCss}>
                    <h4 css={listHeadingCss}>Shop</h4>
                    <ul css={css`
                        list-style-type: none;
                        ${mobile}{
                            margin-bottom: 4rem;
                        }
                    `}>
                        <li>
                            <Link to="/shop">
                                All Products
                            </Link>
                        </li>
                    </ul>
                </div>
                <div css={listColumsCss}>
                    <h4 css={listHeadingCss}>Makers</h4>
                    <ul css={css`
                        list-style-type: none;
                        ${mobile}{
                            margin-bottom: 4rem;
                        }
                    `}>
                        {makerLinks?.map(makerLink => (
                            <li key={makerLink.slug?.current}
                            >
                                <Link to={resolveLink(makerLink._type, makerLink.slug?.current)}>
                                    {makerLink.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div css={listColumsCss}>
                    <h4 css={listHeadingCss}>Info</h4>
                    <ul css={css`
                        list-style-type: none;
                        ${mobile}{
                            margin-bottom: 0rem;
                        }
                    `}>
                        {infoLinks?.map(infoLink => (
                            <li key={infoLink.slug?.current}
                            >
                                <Link to={resolveLink(infoLink._type, infoLink.slug?.current)}>
                                    {infoLink.title}
                                </Link>
                            </li>
                        ))}
                        <li>
                          <a href={"mailto:" + "hi@tooprettytoburn.com.au"}>Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </Section>
				<CreditSection>
					<Credit>
						Website by <a href="https://formwork.build/" target="_blank">Formwork</a>
					</Credit>
				</CreditSection>
    </footer>
  )
}

const CreditSection = styled(Section)`
	background: var(--white);
`

const Credit = styled.div`
	font-size: 14px;
	grid-column: 11 / 13;
	margin: 1.5rem 0;
	${tablet}{
		grid-column: 7 / 13;
	}
	${mobile}{
		grid-column: span 12;
		margin: 0 0 3rem;
	}
	/* color: var(--white); */
	a{
		text-decoration: underline;
	}
`

Footer.propTypes = {
    shopLinks: PropTypes.arrayOf(PropTypes.shape({ 
        name:PropTypes.string,
        link: PropTypes.string,
        key: PropTypes.string,
      })),
    makerLinks: PropTypes.arrayOf(PropTypes.shape({ 
        name:PropTypes.string,
        link: PropTypes.string,
        key: PropTypes.string,
      })),
    infoLinks: PropTypes.arrayOf(PropTypes.shape({ 
        name:PropTypes.string,
        link: PropTypes.string,
        key: PropTypes.string,
    }))
}

Footer.defaultProps = {
    shopLinks: [
        {name:'Products',link:"/shop", key:"products"},
        {name:'Cart',link:"/cart", key:"cart"},
    ],
    makerLinks: [
        {name:'Amoln',link:"/amoln", key:"amoln"},
        {name:'Anye Object',link:"/anye-object", key:"anye_object"},
    ],
    infoLinks: [
        {name:'Test',link:"/test", key:"test"},
        {name:'Test1',link:"/test1", key:"test1"},
    ],
}

export default Footer
