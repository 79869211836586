import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'
import { Cross } from '~components/Svg'
import { useSiteState } from "~context/siteContext"
import { useEffect } from 'react'
import Cookies from 'js-cookie'

const AnnouncementBar = ({ className }) => {

	const { announcementMessage, announcementBarColour } = useSiteSettings()
	const [siteState, setSiteState] = useSiteState()

	useEffect(() => {
		if (siteState.menuOpen) {
			setSiteState(prevState => ({
				...prevState,
				announcementBar: false
			}))
		} else if(Cookies.get('TPTB_HIDE_ANNOUNCEMENT')){
			setTimeout(() => {
				setSiteState(prevState => ({
					...prevState,
					announcementBar: false
				}))
			}, 10)
    } else {
			setSiteState(prevState => ({
				...prevState,
				announcementBar: true
			}))
		}
	}, [siteState.announcementBar, siteState.menuOpen])

	const closeAnnouncement = () => {
		Cookies.set('TPTB_HIDE_ANNOUNCEMENT', true, { expires: 7 })
		setSiteState(prevState => ({
			...prevState,
			announcementBar: false,
		}))
	}

	return (
		<Wrap 
			className={className} 
			bgc={announcementBarColour}
			active={siteState.announcementBar}
		>
			<Content active={siteState.announcementBar}>
			<RichText content={announcementMessage}/>
			<Close onClick={() => closeAnnouncement()}>
				<StyledCrossSmall />
			</Close>
			</Content>
		</Wrap>
	)
}

const Wrap = styled.div`
	background-color: ${props => props.bgc};
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--white);
	position: relative;
	height: ${props => props.active ? '36px' : '0px'};
	transition: height 0.25s;
	p {
		margin-bottom: 0;
		padding-top: 2px;
	}
`
const Content = styled.div`
	opacity: ${props => props.active ? '1' : '0'};
	pointer-events: ${props => props.active ? 'all' : 'none'};
	transition: opacity 0.25s;
`
const Close = styled.button`
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	bottom: 0;
	width: 15px;
	height: 15px;
	${mobile}{
		width: 12px;
		height: 12px;
	}
`
const StyledCrossSmall = styled(Cross)`
	color: var(--white);
	display: block;
`

AnnouncementBar.propTypes = {
	className: PropTypes.string
}

export default AnnouncementBar