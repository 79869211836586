import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Global, tablet } from "@emotion/react";
import useSafari from '~utils/useSafari'


const Cursor = () => {

  const [showMouse, setShowMouse] = useState(false)
	const [mouseX, setMouseX] = useState(false)
	const [mouseY, setMouseY] = useState(false)

	useLayoutEffect(() => {
		window.addEventListener('mousemove', e => {
      setShowMouse(true)
      setMouseX(e.clientX)
      setMouseY(e.clientY)
		})
		return window.removeEventListener('mousemove', e => {
      setShowMouse(true)
			setMouseX(e.clientX)
			setMouseY(e.clientY)
		})
  }, [])

	const isSafari = useSafari()

  // useLayoutEffect(() => {
	// 	window.addEventListener('mouseout', e => {
  //     setShowMouse(false)
	// 	})
	// 	return window.addEventListener('mouseout', e => {
  //     setShowMouse(false)
	// 	})
  // }, [])

	if(isSafari) return null

  return (
	<>
		<Global styles={css`
			html * {
				cursor: none;
				@media (max-width: 1024px){
					cursor: unset;
				}
			}
		`} />
		<img src="/images/match.png" 
		style={{
			left: mouseX + 'px',
			top: mouseY + 'px',
		}}
		css={css`
			border-radius: 10px;
			overflow: hidden;
			position: fixed;
			width: 88px;
			height: 114.5px;
      opacity: ${showMouse ? `1`: `0`};
      pointer-events: none;
      transform: translate(-4px, -4px);
			z-index: 9999;
			@media (max-width: 1024px){
				display: none;
			}
		`}/>
	</>
  )
}

Cursor.propTypes = {
}

Cursor.defaultProps = {
}

export default Cursor
