import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from "gatsby"
import { breakpoints } from '~styles/global'

const Button = ({ children, to, onClick, primary, large, className }) => {

  const { mobile } = breakpoints

  const buttonCss = css`
    background: ${ primary ? 'var(--green)' : 'white' };
    color: ${ primary ? 'var(--white)' : 'var(--black)' };
    border-radius: 100px;
    padding: ${ large ? '13px 2.2em 8px' : '10px 32px 6px;' };
    transition: all 0.3s ease-in-out;
    ${ !large ? 'min-width: 208px' : null };
    ${mobile} {
      padding: ${ large ? '13px 2.2em 8px' : '13px 32px 9px;' };
    }
    &:hover {
      background: ${ primary ? 'var(--lightGreen)' : 'var(--lightGrey)' };
      color: ${ primary ? 'var(--white)' : 'var(--black)' };
    }
  `

  if(to){
    return (
      <Link
        to={to}
        className={className}
      >
        <button css={buttonCss}
          className={ large ? 'h2' : 'h3' }
        >
          {children}
        </button>
      </Link>
    )
  }
  else if(onClick){
    return (
      <button
        css={buttonCss}
        className={ `${large ? 'h2' : 'h3'} ${className}` }
        onClick={e => onClick(e)}
      >
        {children}
      </button>
    )
  }
  else {
    return null
  }
}

Button.propTypes = {
  primary: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  large: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  primary: true,
  children: 'Add To Cart',
  large: false,
}


export default Button
