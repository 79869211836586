import React, { useState, useEffect } from "react"
import resolveLink from "~utils/resolveLink"
import { Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import sanityClient from '@sanity/client'

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_SANITY_API_TOKEN,
  useCdn: false
})

const SanityLink = ({ id, children }) => {
  const [to, setTo] = useState('/#')
  const [type, setType] = useState(undefined)
  useEffect(() => {
    if(id){
      client.fetch(`*[_id == '${id}']`).then(docs => {
        if(docs.length){
          setTo(resolveLink(docs[0]))
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Link to={to}>
      {children}
    </Link>
  )
}

const serializers = {
  marks: {
    undefined: () => null,
    internalLink: props => {
      return <SanityLink id={props.mark.page?._ref}>{props.children}</SanityLink>
    }
  }
}

const RichText = ({ content, className }) => <BlockContent blocks={content} serializers={serializers} className={className}/>

export default RichText
