import React, { useLayoutEffect, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react';
import NavLink from '../NavLink'
import { Logo } from '../Svg';
import { breakpoints } from "~styles/global"
import { Link } from 'gatsby';
import { useSiteState, useCart } from "~context/siteContext"
import { useLocation } from "@reach/router"
import styled from '@emotion/styled';

const Header = ({ className }) => {

  const [siteState, setSiteState] = useSiteState()
  
  const { cartCount } = useCart() 

  const { tablet } = breakpoints
	const loc = useLocation()

	const isHome = loc.pathname === '/'

  return (
      <HeaderEl
				isHome={isHome}
        className={className}
        css={css`
          display: grid;
          grid-template-columns: 1fr 206px 1fr;
          padding: 0 6rem;
          transition: 0.35s color;
          height: 144px;
          align-items: center;
          position: relative;
          z-index: 2;
          width:100%;
          box-sizing:border-box;
          top: 0px;
      `}>
          <nav css={css`
              display: inline-grid;
              justify-content: start;
              grid-template-columns: max-content max-content;
              grid-column-gap: 6rem;
              align-items: center;
              ${tablet} {
                  grid-column-gap: 3rem;
              }
          `}>
              <NavLink 
                  activeColor="var(--purple)"
                  to="/shop"
              >
                  Shop
              </NavLink>
              <NavLink
                  activeColor="var(--lightPink)"
                  to="/makers"
              >
                  Makers
              </NavLink>
          </nav>
          <Link to="/" css={css`display:grid;`}>
              <Logo />
          </Link>
          <nav css={css`
            display: inline-grid;
            justify-content: end;
            grid-template-columns: max-content max-content;
            grid-column-gap: 6rem;
            align-items: center;
            ${tablet} {
              grid-column-gap: 3rem;
            }
          `}>
            <NavLink 
              activeColor="var(--pink)"
              to="/about"
            >
              Info
            </NavLink>
            <button
              onClick={() => setSiteState({...siteState, cartOpen: true})}
              className="h2"
              css={css`color: inherit;`}
            >
              Cart {cartCount() > 0 ? `(${cartCount()})`: null}
            </button>
          </nav>  
      </HeaderEl>
    )
}

const HeaderEl = styled.header`
	color: ${props => props.isHome ? '#ffffff' : '#000000'};
	a {
		color: ${props => props.isHome ? '#ffffff' : '#000000'};
	}
`

Header.propTypes = {
    // mode: PropTypes.oneOf(['light', 'dark']),
}

Header.defaultProps = {
    // mode: 'dark',
}


export default Header