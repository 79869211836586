import React, { useEffect, useState, useLayoutEffect } from 'react'
import GlobalStyles from "~styles/global"
import Header from '~components/Header'
import MobileHeader from '~components/MobileHeader'
import Footer from '~components/Footer'
import Main from '~components/Main'
import Cursor from '~components/Cursor'
import "~styles/static.css"
import { breakpoints } from '~styles/global'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useSiteState } from "~context/siteContext"
import { useLocation } from "@reach/router"
import { AnimatePresence, motion } from 'framer-motion'
import Cart from './Cart'
import Loader from '~components/Loader'
import { Helmet } from "react-helmet"
import AnnouncementBar from '~components/AnnouncementBar'

const Layout = ({ children }) => {
  const {mobile} = breakpoints
  const location = useLocation()
  const [siteState, setSiteState] = useSiteState()
	const [loading, setLoading] = useState(true)

  const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `
	
	useEffect(() => {
		window.setTimeout(() => {
			setLoading(false)
		}, 2400)
	}, [])

  return (
    <>
			<Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
				<meta name="facebook-domain-verification" content="zzq1twvmbxtyk32xu0xvr9tafqdji6" />
      </Helmet>
      <GlobalStyles />
			<AnnouncementBar />
			<Loader css={css`
				opacity: ${loading ? '1' : '0'};
				transition: opacity 0.4s;
				pointer-events: none;
			`}/>
      <Header css={css`${mobile}{display:none;}`} isHome={location?.pathname === '/'}/>
      <MobileHeader css={css`display: none;${mobile}{display:grid;}`} isHome={location?.pathname === '/'}/>
      <Cart open={siteState.cartOpen} />
      <Main css={css`
        /* padding-top: 144px; */
        ${mobile}{
          /* padding-top: 70px; */
        }`}>
        <AnimatePresence exitBeforeEnter>
          <div key={location.pathname}>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.6 }}
            >
              {children}
              <Footer />
            </motion.div>
          </div>
        </AnimatePresence>
      </Main>
      <Overlay 
        onClick={() => setSiteState({...siteState, cartOpen: false})}
        css={css`
          pointer-events: ${siteState.cartOpen? `all` : `none`};
      `}/>
      <Cursor />
    </>
  )
}

export default Layout
