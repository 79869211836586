import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import breakpoints from '~styles/global'
import loading from '../images/loading-comp.gif'

const { mobile, tablet } = breakpoints

const Loader = ({ className }) => {
	return (
		<Wrap className={className}>
			<img src={loading} alt="" />
		</Wrap>
	)
}

const Wrap = styled.div`
	background: var(--beige);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
	img{
		max-width: 300px;
		${mobile}{
			max-width: 200px;
		}
	}
`

Loader.propTypes = {
	className: PropTypes.string
}

export default Loader